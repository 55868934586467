const settings = {
  apiNotificationUrl: "https://api.mds.nettalk.com",
  apiNotificationAuth: "b3NjYXJhOnBhc3N3b3JkMUE=",

  apiNoderedUrl: "http://199.193.189.186:1880/api/v1",
  apiNoderedAuth: "alskjao83761jsUUy",

  pageSizeLoad: 25,
  mobileSize: 880,

  accountPrefixApp: "9998",
  accountPrefixPhy: "9999",

  //modules
  moduleCallingCards: "callingcards",
  moduleOmniChannel: "omnichannel",
  modulePbx: "pbx",
};

export default settings;
