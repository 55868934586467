import React, { useState, useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import { updateReportSchedule } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import moment from "moment-timezone";
import NtModalDatePickerField from "../../components/NtModalDatePickerField";
import NtReportScheduleEmailItem from "./NtReportScheduleEmailItem";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { validateEmail } from "../../common/utils/ControllerUtils";
import { da } from "react-day-picker/locale";
import { parseError } from "../../api/client";

function NtReportScheduleUpdateForm({ item, onCancel, onUpdate }) {
  const [loading, setLoading] = useState(false);
  const [receiver, setReceiver] = useState(item.receiver);
  const [subject, setSubject] = useState(item.subject);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(item.message);
  const [status, setStatus] = useState(item.status);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);
  const isActive = item.status == 1;

  const isRequiredFields = () => {
    return receiver && subject && message;
  };

  const isEmailValid = () => {
    return validateEmail(email);
  };

  const handleUpdate = async (status) => {
    setLoading(true);

    setError(null);
    const response = await updateReportSchedule(
      context.user?.environment?.domain,
      item.id,
      receiver,
      subject,
      message,
      status
    );

    Logger("Report Schedule Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const emailsToString = (list) => {
    let data = "";
    list?.map((each) => {
      data += `${each},`;
    });

    if (data) {
      data = data.substring(0, data.length - 1);
    }

    return data;
  };

  const handleEmailAdd = () => {
    const emails = receiver?.split(",");

    if (!emails?.find((each) => each == email)) {
      emails.push(email);

      const data = emailsToString(emails);
      setEmail("");
      setReceiver(data);
    }
  };

  const renderReceiverRow = (item, index) => {
    return (
      <NtReportScheduleEmailItem
        containerStyle={{ marginRight: 6, marginBottom: 6 }}
        key={item}
        email={item}
        onDelete={() => {
          let emails = receiver?.split(",");
          emails = emails?.filter((each) => each != item);
          const data = emailsToString(emails);
          setReceiver(data);
        }}
      />
    );
  };

  const renderReceivers = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText
          style={{
            marginLeft: 4,
            color: colors.lighGray,
            fontWeight: "700",
            fontStyle: "italic",
            fontSize: 13,
          }}
        >
          Receiver list
        </NtText>
        {receiver?.includes("@") && (
          <NtText style={[{ marginTop: 6 }]}>
            {receiver
              ?.split(",")
              ?.map((each, index) => renderReceiverRow(each, index))}
          </NtText>
        )}
      </View>
    );
  };

  const renderAdd = (containerStyle) => {
    return (
      <NtTouchableEffect
        style={[
          {
            alignItems: "center",
            justifyContent: "center",
            width: 30,
            height: 30,
            borderRadius: 15,
            backgroundColor: isEmailValid() ? colors.blue : colors.lighGray,
          },
          containerStyle,
        ]}
        onPress={
          isEmailValid()
            ? () => {
                handleEmailAdd();
              }
            : null
        }
      >
        <NtMaterialIcon name="plus" color="white" />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Schedule</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This update will re-enable the schedule.
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Receiver *"}
        placeholder={"Add New Receiver"}
        value={email}
        setValue={(text) => setEmail(text)}
        renderAction={renderAdd({ marginRight: -5 })}
      />

      {renderReceivers({ marginTop: 10, marginLeft: 10 })}

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Subject *"}
        placeholder={"Subject"}
        value={subject}
        setValue={(text) => setSubject(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Message *"}
        placeholder={"Message"}
        value={message}
        setValue={(text) => setMessage(text)}
      />

      {error && (
        <NtText
          style={{
            marginTop: 6,
            color: "red",
            fontSize: 12,
            fontWeight: "600",
          }}
        >
          {error}
        </NtText>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isActive ? "space-between" : "flex-end",
          marginTop: 25,
        }}
      >
        {isActive && (
          <NtModalActiveButton
            containerStyle={{ marginRight: 15, backgroundColor: "red" }}
            title={"Deactivate"}
            onPress={() => {
              setStatus(0);
              handleUpdate(0);
            }}
            enabled={true}
            icon={"delete"}
          />
        )}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={isActive ? "Update" : "Update & Activate"}
            onPress={() => {
              if (isRequiredFields()) {
                setStatus(1);
                handleUpdate(1);
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 500,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtReportScheduleUpdateForm;
