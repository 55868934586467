import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import pintypesdata from "../data/pintypesdata";
import orderbyoptionsdata from "../data/orderbyoptonsdata";
import NtTabController from "../../components/NtTabController";

function NtPinmanagementAdvanceSearchPicker({
  onCancel,
  onFilter,
  onFilterClear,
  filter,
}) {
  const context = useContext(UserContext);
  const [pin, setPin] = useState(filter?.pin);
  const [name, setName] = useState(filter?.name);
  const [type, setType] = useState(
    pintypesdata.find((each) => each.id == filter?.type)
  );
  const [orderBy, setOrderBy] = useState(
    orderbyoptionsdata.find((each) => each.id == filter?.orderBy)
  );

  const renderPinTypes = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Pin Type</NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={pintypesdata}
          selection={type}
          onPress={(item) => {
            if (type?.id == item.id) {
              setType(null);
            } else {
              setType(item);
            }
          }}
        />
      </View>
    );
  };

  const renderOrderBy = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Order By</NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={orderbyoptionsdata}
          selection={orderBy}
          onPress={(item) => {
            if (orderBy?.id == item.id) {
              setOrderBy(null);
            } else {
              setOrderBy(item);
            }
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Advanced Search</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select Specific parameters for search
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Pin"}
        placeholder={"Pin"}
        value={pin}
        setValue={(text) => setPin(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      {renderPinTypes({ marginTop: 15 })}

      {renderOrderBy({ marginTop: 15 })}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalUnactiveButton
            title={"Clear filters"}
            onPress={() => {
              setPin("");
              setName("");
              setType(null);
              setOrderBy(null);

              if (onFilterClear) {
                onFilterClear();
              }
            }}
          />
          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Search"}
            enabled={true}
            onPress={() => {
              const filter = {};
              if (pin) {
                filter.pin = pin;
              }
              if (name) {
                filter.name = name;
              }
              if (type) {
                filter.type = type.id;
              }
              if (orderBy) {
                filter.orderBy = orderBy?.id;
              }

              if (onFilter) {
                onFilter(filter);
              }
            }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtPinmanagementAdvanceSearchPicker;
