import React, { useState, useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import { createPin, deletePin, resetPin, updatePin } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import moment from "moment-timezone";
import NtModalDatePickerField from "../../components/NtModalDatePickerField";
import { parseError } from "../../api/client";
import { parseDecimalPlaces } from "../../common/utils/ControllerUtils";
import { parseExtension } from "../utils/ExtensionUtils";

function NtExtensionUpdateForm({ item, onCancel, onUpdate, onReset }) {
  const [name, setName] = useState(item.name);
  const [date, setDate] = useState(moment(item.expirationdate));
  const [credit, setCredit] = useState(parseDecimalPlaces(item.credit));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);
  const isActive = item.status == 1;
  const type = item?.tariff;
  const resetConfirmationForm = useRef();

  const isRequiredFields = () => {
    if (type == "admin") {
      return name, date;
    } else if (type == "cabin") {
      return name, credit;
    }
    return name, credit && date;
  };

  const handleUpdate = async () => {
    setLoading(true);

    setError(null);
    const response = await updatePin(
      context.user?.environment?.domain,
      item.id,
      name,
      date,
      credit
    );

    Logger("Pin response Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);

    setError(null);
    const response = await deletePin(
      context.user?.environment?.domain,
      item.id
    );
    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError("Unable to process request");
    }

    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Extension</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Update extension{" "}
        <NtText style={{ fontWeight: "900" }}>
          {parseExtension(item.extension)}
        </NtText>
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      {type != "admin" && (
        <NtModalInputField
          containerStyle={{ marginTop: 15 }}
          title={"Credit *"}
          placeholder={"Credit"}
          footer={`Initial credit was $${parseDecimalPlaces(
            item.initialbalance
          )}`}
          value={credit}
          setValue={(text) => setCredit(text)}
        />
      )}

      {type != "cabin" && (
        <NtModalDatePickerField
          containerStyle={{ flex: 1, marginTop: 15 }}
          title={"Select Date *"}
          placeholder={"Select Date"}
          value={date}
          setValue={(text) => setDate(text)}
        />
      )}

      {error && (
        <NtText
          style={{
            marginTop: 6,
            color: "red",
            fontSize: 12,
            fontWeight: "600",
          }}
        >
          {error}
        </NtText>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent:
            isActive || type == "cabin" ? "space-between" : "flex-end",
          marginTop: 25,
        }}
      >
        <View
          style={{
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isActive && (
            <NtModalActiveButton
              containerStyle={{ marginRight: 15 }}
              title={"Deactivate"}
              onPress={() => {
                handleDelete();
              }}
              enabled={true}
              icon={"delete"}
            />
          )}

          {type == "cabin" && (
            <NtModalActiveButton
              containerStyle={{ backgroundColor: "red" }}
              title={"Reset Cabin"}
              onPress={() => {
                resetConfirmationForm?.current?.show();
              }}
              enabled={true}
              icon={"lock-reset"}
            />
          )}
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={isActive ? "Update" : "Update & Activate"}
            onPress={() => {
              if (isRequiredFields()) {
                handleUpdate();
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtExtensionUpdateForm;
