import React, { useState, useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import { updateCallingRate } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import { parseDecimalPlaces } from "../../common/utils/ControllerUtils";
import { parseError } from "../../api/client";

function NtCallingRatesUpdateForm({ item, onCancel, onUpdate }) {
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState(parseDecimalPlaces(item.cost));
  const [price, setPrice] = useState(parseDecimalPlaces(item.price));
  const [name, setName] = useState(item.name);
  const [status, setStatus] = useState(item.status);
  const [error, setError] = useState(null);

  const context = useContext(UserContext);
  const isActive = item.status == 1;

  const isRequiredFields = () => {
    return cost && price && name;
  };

  const handleUpdate = async (status) => {
    setLoading(true);

    setError(null);
    const response = await updateCallingRate(
      context.user?.environment?.domain,
      item.id,
      cost,
      price,
      name,
      status
    );

    Logger("Report Schedule Patch", response);

    if (response.ok) {
      if (onUpdate) {
        onUpdate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Update Special Rate</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This update will re-enable the rate.
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Cost *"}
        placeholder={"Cost"}
        value={cost}
        setValue={(text) => setCost(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Price *"}
        placeholder={"Price"}
        value={price}
        setValue={(text) => setPrice(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      {error && (
        <NtText
          style={{
            marginTop: 6,
            color: "red",
            fontSize: 12,
            fontWeight: "600",
          }}
        >
          {error}
        </NtText>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isActive ? "space-between" : "flex-end",
          marginTop: 25,
        }}
      >
        {isActive && (
          <NtModalActiveButton
            containerStyle={{ marginRight: 15, backgroundColor: "red" }}
            title={"Deactivate"}
            onPress={() => {
              setStatus(0);
              handleUpdate(0);
            }}
            enabled={true}
            icon={"delete"}
          />
        )}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {loading && (
            <NtActivityIndicator
              color={colors.blue}
              size="small"
              containerStyle={{ marginRight: 25 }}
            />
          )}

          <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={isActive ? "Update" : "Update & Activate"}
            onPress={() => {
              if (isRequiredFields()) {
                setStatus(1);
                handleUpdate(1);
              }
            }}
            enabled={isRequiredFields()}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 500,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtCallingRatesUpdateForm;
