const terminationcausedata = [
  {
    id: "UNKNOWN",
    title: "Unknown",
  },
  {
    id: "NORMAL_CLEARING",
    title: "Normal Clearing",
  },
  {
    id: "ORIGINATOR_CANCEL",
    title: "Originator Cancel",
  },
  {
    id: "CALL_REJECTED",
    title: "Call Rejected",
  },
];
export default terminationcausedata;
