import client, { parseQueryParams } from "./client";
import { generateFullUser } from "../maritime/utils/MessagingClient";
import Logger from "../common/utils/Logger";
import MessageItem from "../maritime/utils/Message/MessageItem";
import moment from "moment-timezone";

export const fetchPins = async (domain, page, pageSize, search, filter) => {
  let endpoint = `/v1/callingcard/pin/domain/${domain}?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.pin) {
    endpoint += `&id=${filter?.pin}`;
  }

  if (filter?.name) {
    endpoint += `&name=${filter?.name}`;
  }

  if (filter?.type) {
    endpoint += `&tariff=${filter?.type}`;
  }

  if (filter?.orderBy) {
    endpoint += `&orderby=creationdate&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=creationdate&order=DESC`;
  }

  if (filter?.status == "active") {
    endpoint += "&active=1";
  } else if (filter?.status == "inactive") {
    endpoint += "&active=0";
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchCdrs = async (domain, page, pageSize, search, filter) => {
  let endpoint = `/v1/callingcard/cdrs/domain/${domain}?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.pin) {
    endpoint += `&pin=${filter?.pin}`;
  }

  if (filter?.callid) {
    endpoint += `&callid=${filter?.callid}`;
  }

  if (filter?.terminatecauseid) {
    endpoint += `&terminatecauseid=${filter?.terminatecauseid}`;
  }

  if (filter?.type) {
    endpoint += `&tariff=${filter?.type}`;
  }

  if (filter?.dateFrom) {
    endpoint += `&dateFilterFrom=${moment(filter?.dateFrom)?.toISOString()}`;
  }

  if (filter?.dateTo) {
    endpoint += `&dateFilterTo=${moment(filter?.dateTo)?.toISOString()}`;
  }

  if (filter?.to) {
    endpoint += `&destination=${filter?.to}`;
  }

  if (filter?.from) {
    endpoint += `&src=${filter?.from}`;
  }

  // if something was sent to the filter for sorting, will respect it but if not, will sort by creation date descending
  if (filter?.orderBy) {
    endpoint += `&orderby=starttime&order=${filter?.orderBy}`;
  } else {
    endpoint += `&orderby=starttime&order=DESC`;
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const createPin = async (
  domain,
  number,
  name,
  expirationDate,
  type,
  initialCredit
) => {
  const body = {
    id: number,
    name: name,
    expirationdate: moment(expirationDate).format("YYYY-MM-DD"),
    tariff: type,
    initialbalance: initialCredit,
    status: 1,
  };

  if (type == "cabin") {
    body.expirationdate = null;
    body.initialbalance = 5000;
  } else if (type == "admin") {
    body.initialbalance = 500;
  }

  const response = await client.post(
    `/v1/callingcard/pin/domain/${domain}`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const deletePin = async (domain, id) => {
  const response = await client.delete(
    `/v1/callingcard/pin/domain/${domain}/${id}`
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const updatePin = async (domain, id, name, expirationDate, credit) => {
  const date = moment(expirationDate);

  const body = {
    name: name,
    expirationdate: date?.isValid() ? date.format("YYYY-MM-DD") : null,
    initialbalance: credit,
    status: 1,
  };

  const response = await client.patch(
    `/v1/callingcard/pin/domain/${domain}/${id}`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const resetPin = async (domain, pins) => {
  const body = {
    list: pins,
  };

  const response = await client.post(
    `/v1/callingcard/pin/domain/${domain}/reset`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchReportSchedules = async (
  domain,
  page,
  pageSize,
  search,
  filter
) => {
  let endpoint = `/v1/reportschedule/domain/${domain}?page=${page}&pagesize=${pageSize}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  if (filter?.status == "active") {
    endpoint += "&status=1";
  } else if (filter?.status == "inactive") {
    endpoint += "&status=0";
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const updateReportSchedule = async (
  domain,
  id,
  receiver,
  subject,
  message,
  status
) => {
  const body = {
    receiver: receiver,
    subject: subject,
    message: message,
    status: status,
  };

  const response = await client.patch(
    `/v1/reportschedule/domain/${domain}/id/${id}`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchTariffs = async (domain, page = 1, pageSize = 25) => {
  const response = await client.get(
    `/v1/callingcard/tariff/domain/${domain}?page=${page}&pagesize=${pageSize}&orderby=id`
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const fetchCallingRates = async (
  domain,
  tariff,
  page = 1,
  pageSize = 25,
  search,
  filter
) => {
  let endpoint = `/v1/callingcard/rates/domain/${domain}?page=${page}&pagesize=${pageSize}&tariff=${tariff}`;

  if (search) {
    endpoint += `&search=${search}`;
  }

  const response = await client.get(endpoint);

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const createCallingRate = async (
  domain,
  tariff,
  destination,
  cost,
  price,
  name
) => {
  const body = {
    destination: destination,
    cost: parseFloat(cost),
    price: parseFloat(price),
    name: name,
    tariff: tariff,
  };

  const response = await client.post(
    `/v1/callingcard/rates/domain/${domain}`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export const updateCallingRate = async (
  domain,
  id,
  cost,
  price,
  name,
  status
) => {
  const body = {
    cost: parseFloat(cost),
    price: parseFloat(price),
    name: name,
    status: status,
  };

  const response = await client.patch(
    `/v1/callingcard/rates/domain/${domain}/${id}`,
    body
  );

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};
