import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import BaseController from "../../common/base/BaseController";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtPageActionButton from "../../components/NtPageActionButton";
import NtListHeader from "../../components/NtListHeader";
import { asyncStorageSetData } from "../../common/utils/ControllerUtils";
import NtListEmpty from "../../components/NtListEmpty";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import ringgroupcsvcolumndata from "../data/ringgroupcsvcolumndata";
import Logger from "../../common/utils/Logger";
import settings from "../../config/settings";

class RingGroupController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    data: [],
    totalItems: 0,
    searchText: "",
    searchFilter: null,
  };

  column_location = "@ringgroup_column_storage";

  page = 1;

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      ringgroupcsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDataFetch = async () => {
    // Logger("Fetching Pins data");
    // this.setState({ loading: true });
    // const response = await fetchPins(
    //   this.context.user?.environment?.domain,
    //   this.page,
    //   settings.pageSizeLoad,
    //   this.state.searchText,
    //   this.generateFullFilter()
    // );
    // Logger("Data response GET: ", response);
    // if (response.ok) {
    //   Logger("Loading data total items", response.data?.pins?.length);
    //   if (this.page == 1) {
    //     Logger("Loading for the first time.");
    //     this.setState({
    //       data: response.data?.pins,
    //       totalItems: response.data?.total,
    //     });
    //   } else {
    //     Logger("Loading more.");
    //     this.setState({
    //       data: this.state.data.concat(response.data?.pins),
    //     });
    //   }
    // }
    // this.setState({ loading: false });
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"Ring Groups"}
        showFooter={true}
        showBackButton={true}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              // this.addAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
        </View>

        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={ringgroupcsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"ringgroup"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />
          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
});

export default RingGroupController;
