import React from "react";
import { View, StyleSheet } from "react-native";
import NtRootPageContainer from "../../root/components/NtRootPageContainer";
import { deleteAppAccess, fetchAppAccess } from "../../api/appaccess";
import Logger from "../../common/utils/Logger";
import NtLoadMoreButton from "../../components/NtLoadMoreButton";
import {
  asyncStorageSetData,
  showToast,
} from "../../common/utils/ControllerUtils";
import colors from "../../config/colors";
import NtModal from "../../components/NtModal";
import NtAppAccessNewForm from "../components/NtAppAccessNewForm";
import NtAppAccessUploadForm from "../components/NtAppAccessUploadForm";
import NtPageActionButton from "../../components/NtPageActionButton";
import appaccesscsvcolumndata from "../data/appaccesscsvcolumndata";
import BaseController from "../../common/base/BaseController";
import NtListHeader from "../../components/NtListHeader";
import settings from "../../config/settings";
import NtListRecordsTitle from "../../components/NtListRecordsTitle";
import NtListRow from "../../components/NtListRow";
import { parseExtension } from "../utils/ExtensionUtils";
import NtListEmpty from "../../components/NtListEmpty";

class AppAccessController extends BaseController {
  state = {
    ...super.state,
    loading: false,
    deleting: false,
    data: [],
    totalItems: 0,
  };

  column_location = "@appaccess_column_storage";
  page = 1;

  addAccessModalRef = React.createRef();
  uploadAccessModalRef = React.createRef();

  componentDidMount() {
    this.didFocusListener = this.props.navigation.addListener(
      "focus",
      this.handleFocus
    );

    this.handleDefaultColumnsFetch(
      this.column_location,
      appaccesscsvcolumndata.filter((each) => each.default == true)
    );

    this.handleDataFetch();
  }

  componentWillUnmount() {
    // Remove the event listener
    this.didFocusListener();
  }

  handleFocus = () => {
    Logger("On focus change");
    this.handleAccessControl(settings.modulePbx);
  };

  handleDelete = async (id) => {
    this.setState({ deleting: true });

    const response = await deleteAppAccess(id);
    if (response.ok) {
      showToast("Success", "Successfully deleted the app access.");
      this.page = 1;
      this.handleDataFetch();
    }

    this.setState({ deleting: false });
  };

  handleDataFetch = async () => {
    Logger("Fetching App Access data");

    const response = await fetchAppAccess(this.page, settings.pageSizeLoad);
    Logger("AppAccess response GET: ", response);

    if (response.ok) {
      Logger("Loading data total items", response.data?.securityCodes?.length);

      if (this.page == 1) {
        Logger("Loading for the first time.");
        this.setState({
          data: response.data?.securityCodes,
          totalItems: response.data?.total,
        });
      } else {
        Logger("Loading more.");

        this.setState({
          data: this.state.data.concat(response.data?.securityCodes),
        });
      }
    }

    this.setState({ loading: false });
  };

  handleExportFetch = async () => {
    Logger("Fetching App Access export data");

    const response = await fetchAppAccess(1, 10000000);
    Logger("Data response GET: ", response);

    if (response.ok) {
      return response.data.securityCodes;
    }
    return [];
  };

  handleExportDataParce = (item, column) => {
    if (column == "extension") {
      return parseExtension(item[column]);
    }
    return item[column];
  };

  renderAddNewForm = () => {
    return (
      <NtModal
        ref={this.addAccessModalRef}
        renderBody={
          <NtAppAccessNewForm
            onCancel={() => {
              this.addAccessModalRef.current?.dismiss();
            }}
            onCreate={() => {
              this.page = 1;
              this.handleDataFetch();
              this.addAccessModalRef.current?.dismiss();
              showToast("Success", "Successfully created the new app access");
            }}
          />
        }
      />
    );
  };

  renderUploadForm = () => {
    return (
      <NtModal
        ref={this.uploadAccessModalRef}
        renderBody={
          <NtAppAccessUploadForm
            onCancel={() => {
              this.uploadAccessModalRef.current?.dismiss();
            }}
            onUploaded={() => {
              this.page = 1;
              this.handleDataFetch();
              this.uploadAccessModalRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  renderRow = (item, index) => {
    return (
      <NtListRow
        key={index}
        item={item}
        columns={this.state.columns}
        renderCustom={(item, column) => {
          if (column == "extension") {
            return parseExtension(item[column]);
          }
        }}
        onActionIcon={"delete"}
        onActionColor={"red"}
        onActionPress={() => {
          this.handleDelete(item.id);
        }}
      />
    );
  };

  render() {
    return (
      <NtRootPageContainer
        pageTitle={"App Access"}
        showFooter={true}
        showBackButton={true}
        maxWidth={"100%"}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 15 }}
        >
          <NtListRecordsTitle count={this.state.totalItems} />

          <NtPageActionButton
            icon={"plus"}
            onPress={() => {
              this.addAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 15 }}
          />
          <NtPageActionButton
            icon={"arrow-up"}
            onPress={() => {
              this.uploadAccessModalRef.current?.show();
            }}
            containerStyle={{ marginLeft: 10 }}
          />
        </View>
        <View
          style={{
            flex: 1,
            marginTop: 15,
          }}
        >
          <NtListHeader
            visible={this.state.data?.length > 0}
            headerContainerStyle={{ marginRight: 35 }}
            columns={appaccesscsvcolumndata}
            selectedColumns={this.state.columns}
            onColumnUpdate={(updated) => {
              asyncStorageSetData(this.column_location, updated);
              this.setState({ columns: updated });
            }}
            exportFileName={"app-access"}
            exportDataParser={this.handleExportDataParce}
            exportFetchDataAsync={this.handleExportFetch}
          />

          <NtListEmpty visible={this.state.data?.length == 0} />
          {this.state.data?.map((each, index) => this.renderRow(each, index))}

          {
            <View>
              <NtLoadMoreButton
                loadingText={"Loading"}
                title="Load More Records"
                loading={this.state.loading}
                containerStyle={{ marginTop: 40, marginBottom: 40 }}
                onPress={() => {
                  this.page = this.page + 1;
                  this.handleDataFetch();
                }}
              />
            </View>
          }
        </View>
        {this.renderAddNewForm()}
        {this.renderUploadForm()}
      </NtRootPageContainer>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  title: {
    color: colors.lighGray,
    fontWeight: "500",
    fontSize: 15,
  },
});

export default AppAccessController;
