import React, { useState, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import NtTouchableEffect from "../../components/NtTouchableEffect";

import Logger from "../../common/utils/Logger";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import {
  handlePinUploadCreate,
  handlePinUploadFailure,
  handlePinUploadReset,
} from "../utils/PinUploadParser";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { resetPin } from "../../api/billing";

function NtBulkResetActionForm({ onCancel, onUploaded }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [message, setMessage] = useState(null);

  const context = useContext(UserContext);

  const handleUpload = async () => {
    setLoading(true);
    setMessage(null);
    let response = await handlePinUploadReset(file, context.user?.environment);

    const data = response.data;
    response = await resetPin(context.user?.environment?.domain, data);
    Logger("Finished reseting uploaded pins: ", response);
    if (response.ok) {
      setMessage(`Successfully reset ${data.length} cabins`);
    } else {
      setMessage("Unable to process request.");
    }

    setUploadResponse(response);
    setLoading(false);
  };

  const handleDone = () => {
    if (onUploaded) {
      onUploaded(uploadResponse);
    }
  };

  const isRequiredFields = () => {
    return file;
  };

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
    setMessage(null);
  };

  const renderChooseCSV = () => {
    if (file) {
      return null;
    }
    return (
      <NtTouchableEffect
        style={{}}
        onPress={() => {
          document.getElementById("selectFile").click();
        }}
      >
        <NtText style={{ fontSize: 13, fontWeight: "400", color: colors.blue }}>
          Choose CSV file
        </NtText>
      </NtTouchableEffect>
    );
  };

  const renderInfoCSV = () => {
    if (!file) {
      return null;
    }
    return (
      <View>
        <NtText>{file?.name}</NtText>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <NtMaterialIcon name="alert-outline" />
        <NtText style={[styles.title, { marginLeft: 6 }]}>
          Manage Cabin Pins Reset
        </NtText>
      </View>

      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        This will allow to reset a bulk amount. column MUST be named (case
        sensitive):{" "}
        <NtText style={[styles.subtitle, { fontWeight: "bold" }]}>pin</NtText>
      </NtText>

      <View
        style={{
          borderRadius: 4,
          borderWidth: 1,
          borderColor: colors.lightest,
          backgroundColor: colors.lighestGray,
          justifyContent: "center",
          padding: 10,
          marginTop: 30,
        }}
      >
        {renderChooseCSV()}
        {renderInfoCSV()}
      </View>
      {/* here */}
      {message && (
        <NtText
          style={[
            styles.message,
            { marginTop: 10 },
            uploadResponse?.ok ? {} : { color: "red" },
          ]}
        >
          {message}
        </NtText>
      )}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={uploadResponse ? "Done" : "Upload"}
          onPress={
            isRequiredFields()
              ? () => {
                  if (uploadResponse) {
                    handleDone();
                  } else {
                    handleUpload();
                  }
                }
              : null
          }
          enabled={isRequiredFields()}
        />
      </View>

      <input
        id="selectFile"
        type="file"
        name="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={changeHandler}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.lighGray,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  message: {
    color: "green",
    fontSize: 13,
    fontWeight: "600",
  },
});

export default NtBulkResetActionForm;
