import React, { useState, useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import NtModalDatePickerField from "../../components/NtModalDatePickerField";
import terminationcausedata from "../data/terminationcausedata";
import pintypesdata from "../data/pintypesdata";
import Logger from "../../common/utils/Logger";
import orderbyoptionsdata from "../data/orderbyoptonsdata";
import moment from "moment-timezone";
import NtTabController from "../../components/NtTabController";

function NtPinCdrAdvanceSearchPicker({
  onCancel,
  onFilter,
  onFilterClear,
  filter,
}) {
  const context = useContext(UserContext);
  const [callid, setCallid] = useState(filter?.callid ? filter?.callid : "");
  const [pin, setPin] = useState(filter?.pin);
  const [to, setTo] = useState(filter?.to);
  const [from, setFrom] = useState(filter?.from);
  const [terminationCause, setTerminationCause] = useState(
    terminationcausedata.find((each) => each.id == filter?.terminatecauseid)
  );
  const [type, setType] = useState(
    pintypesdata.find((each) => each.id == filter?.type)
  );
  const [dateFrom, setDateFrom] = useState(filter?.dateFrom);
  const [dateTo, setDateTo] = useState(filter?.dateTo);
  const [orderBy, setOrderBy] = useState(
    orderbyoptionsdata.find((each) => each.id == filter?.orderBy)
  );

  const renderTerminationCause = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>
          Termination Cause
        </NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={terminationcausedata}
          selection={terminationCause}
          onPress={(item) => {
            if (terminationCause?.id == item.id) {
              setTerminationCause(null);
            } else {
              setTerminationCause(item);
            }
          }}
        />
      </View>
    );
  };

  const renderPinTypes = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Pin Type</NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={pintypesdata}
          selection={type}
          onPress={(item) => {
            if (type?.id == item.id) {
              setType(null);
            } else {
              setType(item);
            }
          }}
        />
      </View>
    );
  };

  const renderOrderBy = (containerStyle) => {
    return (
      <View style={containerStyle}>
        <NtText style={[styles.fieldTitle, { marginLeft: 4 }]}>Order By</NtText>

        <NtTabController
          containerStyle={{ marginTop: 4 }}
          options={orderbyoptionsdata}
          selection={orderBy}
          onPress={(item) => {
            if (orderBy?.id == item.id) {
              setOrderBy(null);
            } else {
              setOrderBy(item);
            }
          }}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Advanced Search</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Select Specific parameters for search
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Call-ID"}
        placeholder={"Enter Call Id"}
        value={callid}
        setValue={(text) => setCallid(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Pin"}
        placeholder={"Pin"}
        value={pin}
        setValue={(text) => setPin(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"From"}
        placeholder={"From"}
        value={from}
        setValue={(text) => setFrom(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"To"}
        placeholder={"to"}
        value={to}
        setValue={(text) => setTo(text)}
      />

      <View
        style={{ marginTop: 20, flexDirection: "row", alignItems: "center" }}
      >
        <NtModalDatePickerField
          containerStyle={{ flex: 1 }}
          title={"From Date"}
          placeholder={"Enter From Date"}
          isTimeEnabled={true}
          value={dateFrom}
          setValue={(text) => {
            setDateFrom(new Date(text));
          }}
        />

        <NtModalDatePickerField
          containerStyle={{ flex: 1, marginLeft: 15 }}
          title={"To Date"}
          placeholder={"Enter To Date"}
          isTimeEnabled={true}
          value={dateTo}
          setValue={(text) => {
            setDateTo(new Date(text));
          }}
        />
      </View>

      {renderTerminationCause({ marginTop: 15 })}

      {renderPinTypes({ marginTop: 15 })}

      {renderOrderBy({ marginTop: 15 })}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 25,
        }}
      >
        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <NtModalUnactiveButton
            title={"Clear filters"}
            onPress={() => {
              setCallid("");
              setPin("");
              setFrom("");
              setTo("");
              setDateFrom(null);
              setDateTo(null);
              setTerminationCause(null);
              setType(null);
              setOrderBy(null);

              if (onFilterClear) {
                onFilterClear();
              }
            }}
          />
          <NtModalActiveButton
            containerStyle={{ marginLeft: 15 }}
            title={"Search"}
            enabled={true}
            onPress={() => {
              const filter = {};
              if (pin) {
                filter.pin = pin;
              }
              if (callid) {
                filter.callid = callid;
              }
              if (to) {
                filter.to = to;
              }
              if (from) {
                filter.from = from;
              }
              if (terminationCause) {
                filter.terminatecauseid = terminationCause?.id;
              }
              if (type) {
                filter.type = type.id;
              }
              if (dateFrom) {
                filter.dateFrom = dateFrom;
              }
              if (dateTo) {
                filter.dateTo = dateTo;
              }
              if (orderBy) {
                filter.orderBy = orderBy?.id;
              }

              if (onFilter) {
                onFilter(filter);
              }
            }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 600,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  cancelButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.darkest,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
  },
  addButton: {
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.blue,
  },
  uploadResponseText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.darkest,
  },
  rowText: {
    fontSize: 13,
    fontWeight: "600",
    color: colors.lighGray,
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtPinCdrAdvanceSearchPicker;
