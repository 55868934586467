import React from "react";
import { View, StyleSheet } from "react-native";
import colors from "../../config/colors";
import NtText from "../../components/NtText";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtReportScheduleEmailItem({ containerStyle, email, onDelete }) {
  const renderDelete = (containerStyle) => {
    if (!onDelete) {
      return null;
    }

    return (
      <NtTouchableEffect
        style={[{ marginLeft: 6 }, containerStyle]}
        onPress={onDelete}
      >
        <NtMaterialIcon
          name="delete"
          size={18}
          containerStyle={{ marginRight: -3 }}
        />
      </NtTouchableEffect>
    );
  };

  return (
    <View style={[styles.container, containerStyle]}>
      <NtText style={[styles.text]}>{email}</NtText>
      {renderDelete()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lighestGray,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
    paddingBottom: 3,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },

  text: {
    fontSize: 12,
    fontWeight: "700",
    color: colors.lighGray,
  },
});

export default NtReportScheduleEmailItem;
