import React from "react";
import { View, StyleSheet } from "react-native";
import UserContext from "../../context/UserContext";
import settings from "../../config/settings";
import Logger from "../utils/Logger";
import { asyncStorageGetData } from "../utils/ControllerUtils";
import routes from "../../navigation/routes";
import { navigationRef } from "../../../App";

class BaseController extends React.Component {
  static contextType = UserContext;

  state = {
    columns: null,
    isMobile: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize = () => {
    if (window.innerWidth <= settings.mobileSize && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (window.innerWidth > settings.mobileSize && this.state.isMobile) {
      this.setState({ isMobile: false });
    }

    if (this.handleExtraResize) {
      this.handleExtraResize(window.innerHeight, window.innerWidth);
    }
  };

  handleDefaultColumnsFetch = async (location, defaultValue) => {
    let columns = await asyncStorageGetData(location);
    columns = JSON.parse(columns);

    if (columns === null) {
      columns = defaultValue;
    }

    this.setState({ columns: columns });
  };

  handleAccessControl = (module) => {
    if (
      this.context.organization &&
      this.context.organization?.modules_enabled
    ) {
      Logger(
        "---------------- checking modules",
        this.context.organization?.modules_enabled?.hasOwnProperty(module),
        module
      );
      if (this.context.organization?.modules_enabled?.hasOwnProperty(module)) {
        if (!this.context.organization?.modules_enabled[module]) {
          Logger(
            "Oops.. User wants to access a module that does not have access to",
            this.context.organization?.modules_enabled
          );
          navigationRef.current?.navigate(routes.upgrade, { module: module });
        }
      }
      // this.context.organization.modules_enabled[module] = false;
    }
  };
}

const styles = StyleSheet.create({
  container: {},
});

export default BaseController;
