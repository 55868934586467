import React, { useState, useRef, useContext } from "react";
import { View, StyleSheet } from "react-native";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtText from "../../components/NtText";
import NtModal from "../../components/NtModal";
import UserContext from "../../context/UserContext";
import NtBulkResetActionForm from "./NtBulkResetActionForm";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtBulkResetButton({ containerStyle }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const uploadActionModalRef = useRef();
  const context = useContext(UserContext);

  const renderUploadForm = () => {
    return (
      <NtModal
        ref={uploadActionModalRef}
        renderBody={
          <NtBulkResetActionForm
            onCancel={() => {
              uploadActionModalRef.current?.dismiss();
            }}
            onUploaded={() => {
              uploadActionModalRef.current?.dismiss();
            }}
          />
        }
      />
    );
  };

  return (
    <NtTouchableEffect
      style={[styles.container, containerStyle]}
      onPress={() => {
        uploadActionModalRef.current?.show();
      }}
    >
      <NtMaterialIcon name="alert-outline" color="white" size={15} />

      <NtText style={[styles.text, { marginLeft: 6 }]}>Cabin Pin Reset</NtText>

      {renderUploadForm()}
    </NtTouchableEffect>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "red",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 20,
  },
  text: {
    color: "white",
    fontSize: 13,
    fontWeight: "600",
  },
});

export default NtBulkResetButton;
