import React, { useState, useContext, useRef } from "react";
import { View, StyleSheet } from "react-native";
import NtText from "../../components/NtText";
import colors from "../../config/colors";
import NtActivityIndicator from "../../components/NtActivityIndicator";
import UserContext from "../../context/UserContext";
import NtModalUnactiveButton from "../../components/NtModalUnactiveButton";
import NtModalActiveButton from "../../components/NtModalActiveButton";
import NtModalInputField from "../../components/NtModalInputField";
import { createCallingRate, updateReportSchedule } from "../../api/billing";
import Logger from "../../common/utils/Logger";
import NtReportScheduleEmailItem from "./NtReportScheduleEmailItem";
import NtTouchableEffect from "../../components/NtTouchableEffect";
import NtMaterialIcon from "../../components/NtMaterialIcon";
import { validateEmail } from "../../common/utils/ControllerUtils";
import { parseError } from "../../api/client";

function NtCallingRatesTariffNewForm({ tariff, item, onCancel, onCreate }) {
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState("");
  const [cost, setCost] = useState("");
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const isRequiredFields = () => {
    return destination && cost && price && name;
  };

  const handleCreate = async () => {
    setLoading(true);

    setError(null);
    const response = await createCallingRate(
      context.user?.environment?.domain,
      tariff,
      destination,
      cost,
      price,
      name
    );

    Logger("Calling Cards add", response);

    if (response.ok) {
      if (onCreate) {
        onCreate();
      }
    } else {
      setError(parseError(response));
    }

    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <NtText style={styles.title}>Add new Special Rate</NtText>
      <NtText style={[styles.subtitle, { marginTop: 6 }]}>
        Stablish special rates for "
        <NtText style={{ fontWeight: "bold" }}>{tariff}</NtText>" calling cards
      </NtText>

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Dial Prefix *"}
        placeholder={"Dial Prefix"}
        value={destination}
        setValue={(text) => setDestination(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Cost *"}
        placeholder={"Cost"}
        value={cost}
        setValue={(text) => setCost(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Price *"}
        placeholder={"Price"}
        value={price}
        setValue={(text) => setPrice(text)}
      />

      <NtModalInputField
        containerStyle={{ marginTop: 15 }}
        title={"Name *"}
        placeholder={"Name"}
        value={name}
        setValue={(text) => setName(text)}
      />

      {error && (
        <NtText
          style={{
            marginTop: 6,
            color: "red",
            fontSize: 12,
            fontWeight: "600",
          }}
        >
          {error}
        </NtText>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 25,
        }}
      >
        {loading && (
          <NtActivityIndicator
            color={colors.blue}
            size="small"
            containerStyle={{ marginRight: 25 }}
          />
        )}

        <NtModalUnactiveButton title={"Cancel"} onPress={onCancel} />

        <NtModalActiveButton
          containerStyle={{ marginLeft: 15 }}
          title={"Create"}
          onPress={() => {
            if (isRequiredFields()) {
              handleCreate();
            }
          }}
          enabled={isRequiredFields()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 500,
    maxWidth: 500,
    padding: 15,
  },
  title: {
    color: colors.darkest,
    fontSize: 15,
    fontWeight: "600",
  },
  subtitle: {
    color: colors.darker,
    fontSize: 14,
    fontWeight: "500",
  },
  fieldTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: colors.darker,
  },
});

export default NtCallingRatesTariffNewForm;
